<template>
    <div class="main container-fluid">
        <router-view />
    </div>
</template>

<script>

</script>

<style>
html {
    background-image: url(../public/bckgrnd.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}
body {
    background-image: url(../public/bckgrnd.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    overflow-x: hidden;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
}
.header {
    margin: 50px 0 30px 0;
}
.header-col {
    padding-left: 0;
}
h1 {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-top: 80px;
}
.main {
    height: 100%;
}
.screenapp {
    height: calc(100% - 55px);
}
.navbar {
    background-color: transparent;
}
.navbar-right a {
    margin: 5px;
}
.bi-person {
    color: #FFFFFF;
}
.bi-list {
    color: #FFFFFF;
}
.btn-group-vertical {
    margin-top: 44px;
    background: rgba(0, 0, 0, 0.45);
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding-right: 15px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.btn-group-vertical .btn:focus {
    outline: 2px solid grey!important;
    box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
}
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgb(108 117 125 / 50%)!important;
}
.news {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 9px;
    padding-right: 21px;
    padding-top: 5px;
    padding-bottom: 15px;
    height: 410px;
    scrollbar-width: thin;
}
.source-up {
    font-size: 150%;
}
.source-down {
    font-size: 85%;
    font-weight: 300;
    display: flex;
    align-items: flex-end;
    line-height: 30px;
}
.col-md-1 {
    flex: 0 0 auto;
    width: 4%;
}
.row {
    --bs-gutter-x: 0.8rem;
}
.item {
    position: relative;
    height: 70px;
    text-align: left;
    border-radius: 0.5rem;
    background-color: #F6F5F4;
    padding: 0rem 0rem;
    margin: 5px 10px 5px 10px;
    border: none;
    color: #3e3e3e;
    font-size:0.9em;
    padding: 0 40px 0 15px;
    line-height: 17px;
}
.item:before {
    position: absolute;
    background-color: #B9B9B9;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}
.item.neutral:before {
    position: absolute;
    background-color: #B9B9B9;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}
.item.positive:before {
    position: absolute;
    background-color: #00D22E;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}
.item.negative:before {
    position: absolute;
    background-color: #D20000;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}
.item:hover {
    color: #ffffff;
}
.body-enter-active, .body-leave-active {
    transition: opacity .5s;
}
.body-enter, .body-leave-to /* .body-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
.item-body {
    position: absolute;
    z-index: 9999;
    height: 90px;
    width: calc(100% - 21px);
    padding: 5px 5px 0 15px;
    font-size:0.9em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    background-color: #E7E7E7;
}
.item-button {
    width: 100%;
}
.date {
    font-size:0.9em;
}
.meta-info {
    color: #828282;
}
.meta-info-link, .meta-info-link:hover {
    color: #828282;
    text-decoration: underline;
}
.meta-info-text {
    color: #3e3e3e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    overflow-x: hidden;
}
.meta-info-block {
    margin-bottom: 0.5em;
}
[data-title]:after {
    background-color: #fff;
	content: attr(data-title);
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	position: absolute;
	top: 3em;
	left: -50%;
	padding: 2px 8px;
	font-size: 13px;
	font-weight: 300;
	white-space: nowrap;
	color: #444;
	background-image: -moz-linear-gradient(top, #fff, #ddd);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ddd));
	background-image: -webkit-linear-gradient(top, #fff, #ddd);
	background-image: -o-linear-gradient(top, #fff, #ddd);
	background-image: -ms-linear-gradient(top, #fff, #ddd);
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-o-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	-moz-box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
	-o-box-shadow: 0 0 5px #ccc;
	-ms-box-shadow: 0 0 5px #ccc;
	box-shadow: 0 0 5px #ccc;
}
[data-title]:hover:after {
    z-index: 9999;
    position: absolute;
	opacity: 1;
	visibility: visible;
	-moz-transition: all 0.2s ease-out 0.5s;
	-webkit-transition: all 0.2s ease-out 0.5s;
	-o-transition: all 0.2s ease-out 0.5s;
	-ms-transition: all 0.2s ease-out 0.5s;
	transition: all 0.2s ease-out 0.5s;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: initial;
    text-decoration: none;
}
a {
    color: initial;
    text-decoration: none;
}
.footer-link {
    font-weight: 300;
    margin: 0 10px 0 10px;
    white-space: nowrap;
}
.lang-link-ru {
    font-weight: 500;
    margin: 20px 2px 0 10px;
}
.lang-link-en {
    color: #B9B9B9;
    font-weight: 500;
    margin: 20px 10px 0 2px;
}
.header-icon {
    margin: 0px 10px 0 10px;
}
.terms-of-use {
    margin-top: 70px;
    font-weight: 300;
    font-size: 0.8em;
    background-color: #FFFFFF;
    border-radius: 9px;
    padding-right: 11px;
    scrollbar-width: thin;
}
.general-terms {
    display: inline-block;
    position: relative;
    margin: 60px 20px 0px 100px;
}
.oferta {
    margin-top: 70px;
    font-size: 0.9em;
    background-color: #FFFFFF;
    border-radius: 9px;
    padding-right: 11px;
    scrollbar-width: thin;
}
.support-terms {
    border-radius: 9px;
    background-color: #F6F5F4;
    margin: 60px 100px 60px 20px;
}
.support-terms-text {
    margin: 70px 40px 20px 40px;
}
.terms-table {
    margin-top: calc(var(--bs-gutter-x) * .9);
}
.terms-li {
    margin-bottom: 1em;
}
.important-text {
    font-weight: 500;
    font-size: 1em;
}
.country, .country:hover {
    background-color: #FFFFFF;
    color: #3E3E3E;
    height: 31px;
    width: 146px;
    border-radius: 9px;
    line-height: 0;
    text-align: initial;
    box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
    padding-left: 10px;
    padding-bottom: 8px;
    padding-top: 6px;
}
.country:after {
    float: right;
    margin-top: 4px;
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}
.country-menu {
    height: 68px;
    width: 146px;
    border-radius: 9px;
    min-width: 0rem;
    line-height: 0;
    padding-bottom: 5px;padding-top: 5px;
}
.dropdown {
    font-size: 0.9em;
    margin-top: 82px !important;
}
.dropdown-item,  .dropdown-item:hover {
    background-color: #FFFFFF;
    color: #3E3E3E;
    height: 23px;
    border-radius: 9px;
    padding: 0.25rem 0.75rem;
}
.show::after {
    margin-top: 9px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-divider {
    margin: 3px;
    visibility: hidden;
}
.country-text {
    margin-left: 10px;
    margin-bottom: 4px;
    display: inline;
    height: 13px;
}
.round-by {
    display: inline-block;
    width: 15px; height: 15px; /* Ширина и высота */
    border-radius: 50%; /* Превращаем в круг */
    background: url(../public/flag_by.svg) no-repeat -0px -3px; /* Параметры фона */
    background-size: auto 20px; /* Высота фотографии равна высоте элемента */
    margin: 0;
}
.round-ua {
    display: inline-block;
    width: 15px; height: 15px; /* Ширина и высота */
    border-radius: 50%; /* Превращаем в круг */
    background: url(../public/flag_ua.svg) no-repeat -0px -3px; /* Параметры фона */
    background-size: auto 20px; /* Высота фотографии равна высоте элемента */
    margin: 0;
}
.round-ru {
    display: inline-block;
    width: 15px; height: 15px; /* Ширина и высота */
    border-radius: 50%; /* Превращаем в круг */
    background: url(../public/flag_ru.svg) no-repeat 0px -3px; /* Параметры фона */
    background-size: auto 20px; /* Высота фотографии равна высоте элемента */
    margin: 0;
    border-color: #1158C2;
}
@media (min-width: 1920px) {
    .news-block {
        width: 1600px !important;
    }
    .terms-of-use {
        width: 1640px !important;
        height: 843px !important;
    }
    .oferta {
        width: 1088px !important;
    }
    .header-col {
        width: 528px !important;
    }
    footer {
        position: fixed !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1030 !important;
    }
    .general-terms {
        margin: 60px 20px 0px 100px;
    }
}
@media (min-width: 1820px) and (max-width: 1920px) {
    footer {
        position: fixed !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1030 !important;
    }
}
@media (min-width: 1440px) and (max-width: 1820px) {
    .news-block {
        width: 1360px !important;
        font-size: small  !important;
    }
    .terms-of-use {
        width: 1360px !important;
        font-size: small  !important;
    }
    .news {
        height: 346px;
    }
    .item-button {
        height: 57px;
    }
    h1 {
        font-size: 25px;
        margin-top: 30px !important;
    }
    .dropdown {
        margin-top: 30px !important;
    }
    footer {
        position: fixed !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1030 !important;
    }
    .cookie-message {
        height: 120px !important;
        width: 381px !important;
    }
    .cookie-button {
        height: 45px !important;
        width: 155px !important;
        font-size: middle  !important;
    }
    .cookie-text {
        font-size: small  !important;
    }
    .politics {
        height: 25px !important;
    }
    .dropdown-menu {
        width: 139px;
    }
    .general-terms {
        margin: 60px 20px 0px 70px;
    }
}
@media (min-width: 768px) and (max-width: 1440px) {
    .news-block {
        width: 1160px !important;
        font-size: small  !important;
    }
    .terms-of-use {
        width: 1160px !important;
        font-size: small  !important;
    }
    .news {
        height: 295px;
    }
    .item-button {
        height: 47px;
    }
    h1 {
        font-size: 20px;
        margin-top: 20px !important;
    }
    .dropdown {
        margin-top: 20px !important;
    }
    .dropdown-menu {
        width: 116px;
    }
    .general-terms {
        margin: 60px 20px 0px 30px;
    }
    .support-terms {
        margin: 60px 100px 60px 20px;
    }
    footer {
        position: fixed !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1030 !important;
    }
    .cookie-message {
        height: 120px !important;
        width: 381px !important;
    }
    .cookie-button {
        height: 45px !important;
        width: 155px !important;
        font-size: middle  !important;
    }
    .cookie-text {
        font-size: small  !important;
    }
}
@media (max-width: 768px) {
    .cookie-message {
        height: 120px !important;
        width: 281px !important;
    }
    .cookie-button {
        height: 35px !important;
        width: 105px !important;
        font-size: small  !important;
    }
    .cookie-text {
        font-size: small  !important;
    }
    h1 {
        margin-top: 10px !important;
    }
}
.dalee {
    padding: 0.5rem 4rem;
    background-color: #828282;
}
.doc-ol {
   list-style: none;
   counter-reset: li;
}
.doc-li {
    margin-bottom: 1em;
}
.doc-li:before {
    counter-increment: li;
    content: counters(li, ".") ". ";
}
.cookie-message {
    height: 142px;
    width: 468px;
    margin-right: 10px;
    margin-top: 20px;
}
.cookie-text {
    margin: 7px 20px 10px 30px;
}
.cookie-buttons {
    margin-left: 30px;
}
.cookie-button {
    height: 55px;
    width: 200px;
    margin-right: 7px;
    border-radius: 9px;
    background-color: #828282;
    font-wight: 500 !important;
    font-size: large;
    border: none;
}
.settings-cookie {
    background-color: #CCCCCC;
}
.politics {
    margin-top: 20px;
}
.slider {
    pointer-events: none;
    -webkit-appearance: none;
    appearance: none;
    width: 300px;
    height: 23px;
    background: linear-gradient(to right, #00D22E, grey, red);
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 6px;
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 27px;
    height: 27px;
    background: transparent;
    border-radius: 3px;
    border-color: #FFFFFF;
    border: 4px solid white;
}
.slider::-moz-range-thumb {
    width: 27px;
    height: 27px;
    background: #B9B9B9;
    border-radius: 3px;
    border-color: #FFFFFF;
}
.indikator-title {
    margin-bottom: 0.3rem;
    color: white;
}
</style>
