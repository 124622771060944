<template>
    <div class="row justify-content-center">
        <div class="terms-of-use col-md-10"><div class="row">
            <div class="general-terms col-sm-6">
            <div>
                <h4>Условия использования</h4>
                <p>Сервис и входящие в него компоненты, разрабатываемые Группой компаний «Масалович и партнеры» предназначены для задач интернет-мониторинга, а также обработки и анализа открытых данных из сети Интернет. Подчеркиваем, мы используем только открытые данные, которые находятся в свободном доступе в сети Интернет.
    Если Вы используете наш Сервис каким-либо способом, Вы тем самым подтверждаете свое согласие соблюдать следующие правила (все условия, оговоренные далее, относятся как к Сервису в целом, так и ко всем его компонентам
    в отдельности, но не исключает дополнительных условий <router-link class="text-decoration-underline text-reset" to="/public_offer">публичной оферты</router-link>):</p>
                <ol>
                    <li class="terms-li">В случае приобретения доступа к Сервису, Вы имеете право пользоваться Сервисом на том количестве компьютеров/мобильных устройств, которое было указано в заказе. Правообладатель и/или его Партнеры гарантируют работу Сервиса в соответствии с условиями, описанными в руководстве по эксплуатации</li>
                    <li class="terms-li">Правообладатель и/или его Партнеры гарантируют работу Сервиса в соответствии с условиями, описанными
    в руководстве по эксплуатации.</li>
                    <li class="terms-li">Правообладатель и/или его Партнеры не гарантируют работоспособность Сервиса при нарушении условий, описанных в руководстве по эксплуатации, а также в случае нарушения Пользователем условий использования Сервиса.</li>
                    <li class="terms-li">Правообладатель и/или его Партнеры не несут ответственности за сбои в работе связанные с использованием Сервиса или невозможностью использования Сервиса в случае несоответствия ЭВМ Пользователя, операционных систем и сопутствующего программного обеспечения Минимальным системным требованиям.</li>
                    <li class="terms-li">Правообладатель и/или его Партнеры не несут ответственности за использование Пользователем Сервиса
    в злонамеренных целях. При этом Правообладатель и/или его Партнеры оставляют за собой право отслеживать
    и анализировать активность Пользователя (в обезличенном виде) с целью предотвращения использования Сервиса в противоправных целях, нарушающих законодательство Российской Федерации.</li>
                    <li class="terms-li">Пользователь обязуется не использовать Сервис для проверки безопасности сайтов без согласия их владельцев. Пользователь несет ответственность, установленную законодательством Российской Федерации, за совершенные проверки безопасности и все возможные последствия таких проверок.</li>
                    <li class="terms-li">Запрещается производить обратную разработку (обратное проектирование, обратный инжиниринг, реверс-инжиниринг), а также декомпиляцию и/или модификацию исходного кода любых наших продуктов.</li>
                    <li class="terms-li">За нарушение авторских прав нарушитель несет гражданскую, административную или уголовную ответственность в соответствии с законодательством Российской Федерации.</li>
                    <li class="terms-li">После активации учетной записи (за исключением версий, предназначенных для ознакомительных целей) Пользователь приобретает право получать от Правообладателя и/или его Партнеров <a class="text-decoration-underline text-reset" href="#" v-on:click="show = !show">Техническую поддержку</a>
    в течение срока действия лицензии.</li>
                </ol>
            </div>
            </div>
            <transition name="support-terms-body">
                <div class="support-terms col-sm-4" v-if="show">
                    <div class="support-terms-text">
                        <h4>Условия технической поддержки </h4>
                        <p>В рамках Технической поддержки Правообладатель и/или его Партнеры обязуются оказывать следующие услуги:
                            <ul>
                            <li>обновление новых версий как Сервиса в целом, так и всех его компонентов, по мере их появления;</li>
                            <li>организация консультаций и устранение инцидентов
                            (по электронной почте – support@avl.team и через специальную форму обратной связи)</li></ul>
                        </p>
                        <div class="row terms-table">
                            <div class="col">Временной режим регистрации заявок
                                на обслуживание в системе технической поддержки
                                (и по официальному адресу электронной почты)</div>
                            <div class="col">Круглосуточно (24/7)</div>
                        </div>
                        <div class="row terms-table">
                            <div class="col">Временной режим устранения критичных инцидентов</div>
                            <div class="col">С 10:00 до 20:00 часов
                                по московскому времени</div>
                        </div>
                        <div class="row terms-table">
                            <div class="col">Временной режим решения прочих заявок и обработки информационных запросов</div>
                            <div class="col">С 10:00 до 20:00 часов
                                по московскому времени (кроме выходных
                                и праздничных дней)</div>
                        </div>
                        <div class="row terms-table">
                            <div class="col">Время реакции на заявку</div>
                            <div class="col">4 часа (время реакции
                                на заявку включает в себя только рабочее время)</div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
  components: {},
    data(){
        return {
            show: false
        }
    },
}
</script>