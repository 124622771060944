<template>
    <button class="item item-button btn btn-outline-secondary" v-bind:class="newsitem.sentiment" type="button" v-on:click="show = true">
        {{ normalizedTitle }}
    </button>
      <transition name="body">
        <div class="item item-body" v-bind:class="newsitem.sentiment" v-if="show" v-click-outside="onClickOutside">
            <p class="meta-info-text">{{ newsitem.text }}</p>
            <div class="meta-info-block">
                <span class="meta-info date">{{ formatDate }}</span>&nbsp;&middot;
                <span><a class="meta-info meta-info-link" target="_blank" rel="nofollow noopener" v-bind:href="newsitem.card_url">{{ normalizedUrl }}</a></span>
            </div>
        </div>
      </transition>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: "NewsItem",
  props: ["newsitem"],
  data(){
        return {
            show: false
        }
    },
  computed: {
  formatDate: function() {
    let date = new Date(this.newsitem.created_at);
    return date.toLocaleDateString()
  },
  normalizedUrl: function () {
    if (this.newsitem.card_url === null) {
        return;
    }
    const url = this.newsitem.card_url;
    const { hostname } = new URL(url);
    const { protocol } = new URL(url);
    return protocol +"//" + hostname
    },
  normalizedTitle: function() {
    const text = this.newsitem.title;
    let sliced = text.slice(0,100);
        if (sliced.length < text.length) {
        sliced += '...';
        }
    return sliced
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      console.log('Clicked outside. Event: ', event);
      this.show = false;
    },
  }
};
</script>

<style>
</style>