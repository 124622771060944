<template>
  <footer class="container-fluid">
    <ConfirmCookie />
    <nav class="navbar navbar-expand-md">
        <div class="slidecontainer">
          <p class="indikator-title">{{ $t('sentiment_title') }}:</p>
          <input type="range" min="-15" max="15" v-bind:value="sentimentresults" class="slider">
        </div>
        <div class="navbar-nav ms-auto politics">
            <a class="link-light footer-link" href="#">
                {{ $t('conf') }}
            </a>
            <router-link class="link-light footer-link" to="/terms_of_use">
                {{ $t('terms') }}
            </router-link>
        </div>
    </nav>
  </footer>
</template>

<script>
import ConfirmCookie from "@/components/ConfirmCookie.vue"
export default {
  name: 'TheFooter',
  props: ["sentimentresults"],
  components: {
      ConfirmCookie
  }
}
</script>

<style>
</style>