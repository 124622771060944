<template>
    <nav class="navbar navbar-expand-md" v-if="agreecookie == null">
        <div class="news cookie-message ms-auto">
          <div >
          <div class="cookie-text">{{ $t('cookie_message') }}</div>
          <div class="cookie-buttons">
              <button type="button" class="btn btn-secondary cookie-button" @click="setAcceptCookie()">{{ $t('cookie_agree') }}</button>
              <button type="button" class="btn btn-secondary cookie-button settings-cookie">{{ $t('cookie_settings') }}</button>
          </div>
          </div>
        </div>
    </nav>
</template>

<script>
  export default {
      name: 'ConfirmCookie',
      data() {
        return {
            agreecookie: localStorage.getItem("agree-cookie"),
        };
      },
      methods: {
        setAcceptCookie() {
            localStorage.setItem('agree-cookie', 'true');
            this.agreecookie = true;
        },
      },
  }
</script>
