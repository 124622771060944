export default {
    h1: 'Top news of the hour',
    source: 'According to',
    conf: 'Confidentiality',
    terms: 'Terms of use',
    country_ru: 'Russia',
    country_by: 'Belarus',
    country_ua: 'Ukraine',
    cookie_message: 'We use cookies to adapt and improve the quality of the site.',
    cookie_agree: 'Agree',
    cookie_settings: 'Settings',
    dzen: "Dzen",
    sentiment_title: "Sentiment of the day",
}