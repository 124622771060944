export default {
    h1: 'Главные новости часа',
    source: 'По данным',
    conf: 'Конфиденциальность',
    terms: 'Условия использования',
    country_ru: 'Россия',
    country_by: 'Беларусь',
    country_ua: 'Украина',
    cookie_message: 'Мы используем файлы Cookie для адаптации и улучшения качества работы сайта.',
    cookie_agree: 'Согласиться',
    cookie_settings: 'Настройки',
    dzen: "Дзен",
    sentiment_title: "Эмоциональная окраска дня",
}