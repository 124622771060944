<template>
    <div class="row justify-content-center">
    <div class="news-block col-lg-10">
        <div class="row header">
            <div class="col-md-4 header-col">
                <div class="row">
                <div class="col-md-8"><h1>{{ $t('h1') }}</h1></div>
                <div class="dropdown col-md-4 d-md-flex justify-content-md-end">
                  <button class="btn dropdown-toggle country me-md" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,4">
                    <span :class="currentCountry[0].class"></span>
                    <span class="country-text">{{ currentCountry[0].text }}</span>
                  </button>
                  <ul class="dropdown-menu country-menu" aria-labelledby="dropdownMenuButton">
                    <li :key="item.value" v-for="item in countryList">
                        <button class="dropdown-item" type="button" @click="countryClickHandler(item.value)">
                            <span :class="item.class"></span>
                            <span class="country-text">{{ item.text }}</span>
                        </button>
                    <hr class="dropdown-divider">
                    </li>
                  </ul>
                </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col" v-if="gresults">
                <img src="logo_google.png">
                <div class="news">
                        <NewsItem v-for="i in gresults" v-bind:key="i.title" v-bind:newsitem="i" />
                </div>
                <a class="link-light" v-bind:href="gresults[1].start_url" target="_blank" rel="nofollow noopener">
                    <p class="text-white source-down">{{ $t('source') }} Google</p>
                </a>
            </div>
            <div class="col" v-if="yresults">
                <img src="logo_dzen_novosti.png">
                <div  class="news">
                        <NewsItem v-for="i in yresults" v-bind:key="i.title" v-bind:newsitem="i" />
                </div>
                <a class="link-light" v-bind:href="yresults[1].start_url" target="_blank" rel="nofollow noopener">
                    <p class="text-white source-down">{{ $t('source') }} {{ $t('dzen') }}</p>
                </a>
            </div>
            <div class="col" v-if="mresults">
                <img src="logo_mm.png">
                <div  class="news">
                        <NewsItem v-for="i in mresults" v-bind:key="i.title" v-bind:newsitem="i" />
                </div>
                <a class="link-light" v-bind:href="mresults[1].start_url" target="_blank" rel="nofollow noopener">
                    <p class="text-white source-down">{{ $t('source') }} Mediametrics
                    </p>
                </a>
            </div>
        </div>
    </div>
    </div>
    <TheFooter v-bind:sentimentresults="sentimentresults" />
</template>

<script>
import NewsItem from "@/components/NewsItem.vue"
import TheFooter from "@/components/TheFooter.vue"
import axios from 'axios';
export default {
  name: "HomeView",
  components: {
    NewsItem,
    TheFooter
  },
  data() {
    return {
      gresults: null,
      yresults: null,
      mresults: null,
      countries: [
        { text: this.$t('country_ru'), value: "ru", path: "/", class: "round-ru"},
        { text: this.$t('country_by'), value: "by", path: "/?zone=by", class: "round-by"},
        { text: this.$t('country_ua'), value: "ua", path: "/?zone=ua", class: "round-ua"}
      ],
      selectedCountry: "ru",
    };
  },
  methods: {
    getResults: function(path) {
      axios.get(path)
        .then((res) => {
          this.gresults = res.data.filter(function (g) {return g.domain.startsWith("https://news.google.com")})
          this.yresults = res.data.filter(function (g) {return g.domain.startsWith("https://yandex")})
          this.mresults = res.data.filter(function (g) {return g.domain.startsWith("https://mediametrics.ru")})
          var pos = res.data.filter(function (g) {return g.sentiment.startsWith("positive")}).length
          var neg = res.data.filter(function (g) {return g.sentiment.startsWith("negative")}).length
          this.sentimentresults = 0+(-pos)+neg
          console.log(this.sentimentresults)
          })
        .catch((error) => {
          console.error(error);
        });
    },
    countryClickHandler: function(value) {
        this.selectedCountry  = value,
        this.getResults(this.currentCountry[0].path)
    },
  },
  computed: {
    countryList() {return  this.countries.filter(item => item.value !== this.selectedCountry)},
    currentCountry() {return this.countries.filter(item => item.value == this.selectedCountry)}
  },
  created() {
    this.getResults();
  },
};
</script>

<style>
</style>