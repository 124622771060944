import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import vClickOutside from 'click-outside-vue3'
import { createI18n } from 'vue-i18n'
import en from './locales/en.js'
import ru from './locales/ru.js'

if (process.env.VUE_APP_BASE_URL) {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;  // backend
}

console.log("BASE_URL", axios.defaults.baseURL);

var lang = localStorage.getItem("locale") || "ru";
const i18n = createI18n({
  locale: lang,
  messages: {ru, en}
})

createApp(App).use(router).use(vClickOutside).use(i18n).mount('#screenApp')

