import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TermsOfUse from '../views/TermsOfUse.vue'
import PubOffer from '../views/PubOffer.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: process.env.VUE_APP_AUTH,
    }
  },
  {
    path: '/terms_of_use',
    component: TermsOfUse,
  },
  {
    path: '/public_offer',
    component: PubOffer,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//router.beforeEach((to, from, next) => {
//    if (to.meta.requiresAuth && !window.oidc.isAuthenticated) {
//      window.oidc.signIn();
//    } else next()
//})

export default router
