<template>
    <div class="row justify-content-center">
        <div class="oferta col-md-8">
            <div class="support-terms-text">
                <h4>Соглашение об оказании услуги (публичная оферта)</h4>
                <p><b>Дата размещения на Сайте «__»______2022 г.</b></p>
                <p>АО «Инфорус» предлагает заключить настоящее Соглашение об оказании Услуги по предоставлению права пользования простой (неисключительной) лицензией на продукт.
                    Предложение адресовано неопределенному кругу дееспособных физических, юридических лиц, индивидуальных предпринимателей и является публичной офертой в соответствии с п. 2 ст. 437 ГК РФ.</p>

                <ol class="doc-ol">
                    <li class="doc-li"><b>ТЕРМИНЫ</b>
                        <p>Стороны используют указанные термины в следующем значении:</p>
                        <ol class="doc-ol">
                            <li class="doc-li"><b>Акцепт</b> – полное и безоговорочное принятие условий настоящего Соглашения путем совершения действий
                                по Регистрации и <b>установления отметки напротив фразы «С условиями Соглашения об оказании услуги ознакомился и согласен»</b>.</li>
                            <li class="doc-li"><b>Сервис</b> – предоставление Исполнителем услуги на Сайте Исполнителя с использованием программно-аппаратного комплекса, позволяющего выявлять ошибки в работе сайта Пользователя, наличие вирусов и (или) ошибок администрирования. Полное описание функционала Сервиса приведено на Сайте. Все права на Сервис
                                и входящие в него элементы в совокупности и по отдельности принадлежат Исполнителю  в полном объеме.
                                Ни одно из положений Соглашения не может трактоваться как передача (отчуждение) исключительных прав
                                на программно-аппаратный комплекс, используемый Сервисом, Пользователю или разрешение использовать
                                не предусмотренными Соглашением способами.</li>
                            <li class="doc-li"><b>Исполнитель</b> – АО «Инфорус» (ИНН 7705990670), являющийся стороной Соглашения и предоставляющее Пользователю право на использование Сервиса. </li>
                            <li class="doc-li"><b>Пользователь</b> – правоспособное физическое лицо (достигшее возраста совершеннолетия в соответствии
                                с законодательством юрисдикции своего проживания, но не менее 18 лет), юридическое лицо или индивидуальный предприниматель, акцептовавший Соглашение.</li>
                            <li class="doc-li"><b>Оплаченный период</b> – срок оплаченного использования Сервиса, исходя из выбранного Тарифа.</li>
                            <li class="doc-li"><b>Сайт Исполнителя (Сайт)</b> – сайт Исполнителя, расположенный на доменном имени https://avl.team, содержащий описание свойств Сервиса. Сайт включает в себя контент, программное обеспечение и иные элементы, необходимые для надлежащего функционирования. Все права на Сайт и входящие в него элементы в совокупности и по отдельности принадлежат Исполнителю в полном объеме. Ни одно из положений Соглашения не может трактоваться как передача (отчуждение) исключительных прав на Сайт Пользователю или разрешение использовать не предусмотренными Соглашением способами.</li>
                            <li class="doc-li"><b>Соглашение</b> – настоящее Соглашение, включая все приложения и правила, указанные в Соглашении либо прямо поименованные как приложения к Соглашению.</li>
                            <li class="doc-li"><b>Тарифы Исполнителя (Тарифы)</b> – прайс-лист Исполнителя, расположенный на Сайте.</li>
                            <li class="doc-li"><b>Законодательство</b> – действующее законодательство РФ.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ПРЕДМЕТ СОГЛАШЕНИЯ. ОБЩИЕ ТРЕБОВАНИЯ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">В соответствии с условиями Соглашения Исполнитель оказывает  Пользователю  услугу по использованию Пользователем Сервиса на условиях выбранного Пользователем Тарифа, а Пользователь обязуется выплатить Исполнителю  вознаграждение согласно выбранному Пользователем Тарифу.</li>
                            <li class="doc-li">Все условия, оговоренные далее, относятся как к Сервису в целом, так и ко всем его компонентам в отдельности. Но не исключает дополнительных условий.</li>
                            <li class="doc-li">Срок доступа к Сервису ограничен сроком действия Тарифа.</li>
                            <li class="doc-li"><b>Изменение Соглашения</b>. Исполнитель имеет право в любой момент изменять Соглашение и все приложения
                                к нему (полностью или в части) в одностороннем порядке, без предварительного согласования с Пользователем. Все изменения вступают в силу на следующий календарный день после размещения на Сайте.</li>
                            <li class="doc-li">Пользователь обязуется самостоятельно следить за изменениями Соглашения и приложений к нему путем периодического ознакомления с актуальной редакцией, не менее одного раза в месяц. Пользователь самостоятельно отвечает за любые последствия, наступившие в связи с неознакомлением с Соглашением
                                и приложениями к нему.</li>
                            <li class="doc-li">В случае если Пользователь не согласен с каким-либо положением Соглашения и (или) приложений к нему,
                                он обязан незамедлительно прекратить использование Сайта и Услуги.</li>
                            <li class="doc-li"><b>Соглашение состоит из</b>:
                                <ul>
                                    <li>«Соглашение об оказании услуги» – постоянно размещенное в публичном доступе на Сайте по адресу
                                        https://avl.team/terms.
                                        Предыдущие редакции Соглашения размещены на Сайте по адресу https://avl.team/terms/archive.</li>
                                    <li>«Тарифы» – постоянно размещенные размещенное в публичном доступе на Сайте по адресу
                                        https://avl.team/tariff</li>
                                    <li>«Условия использования» - общие правила пользования продуктами Группы Компаний «Масалович
                                        и партнеры», постоянно размещенные размещенное в публичном доступе на Сайте по адресу
                                        https://avl.team/termsofuse</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ПОРЯДОК И СПОСОБЫ ИСПОЛЬЗОВАНИЯ СЕРВИСА</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Пользователь вправе использовать Сервис путем проверки сайта Пользователя. Сроки и тип проверки указываются в форме заказа на Сайте. </li>
                            <li class="doc-li">Исполнитель по своему усмотрению может осуществлять модерацию запроса Пользователя и отказать в оказании услуги по предоставлению доступа к Сервису. В этом случае вознаграждение возвращается Пользователю.</li>
                            <li class="doc-li">Пользователю запрещается:
                                <ol class="doc-ol">
                                    <li class="doc-li">Использовать Сервис способом, прямо не предусмотренным Соглашением.</li>
                                    <li class="doc-li">Использовать Сервис для проверки сайтов, не принадлежащих Пользователю.</li>
                                    <li class="doc-li">Использовать Сервис с целью нарушения прав и (или) интересов третьих лиц.</li>
                                    <li class="doc-li">Распространять, копировать или иным образом осуществлять обнародование программного обеспечения, входящего в Сервис.</li>
                                    <li class="doc-li">Пытаться обойти технические ограничения в программном обеспечении, входящем в состав Сервиса.</li>
                                    <li class="doc-li">Производить обратную разработку (обратное проектирование, обратный инжиниринг, реверс-инжиниринг), а также декомпиляцию и/или модификацию исходного кода программного обеспечения, входящего
                                        в Сервис.</li>
                                    <li class="doc-li">Осуществлять использование Сервиса или его частей за пределами срока действия Соглашения.</li>
                                    <li class="doc-li">Использовать Сервис под товарным знаком Пользователя, а также представлять Сервис как собственность Пользователя перед любыми третьими лицами.</li>
                                    <li class="doc-li">Использовать контент Сайта Исполнителя и брендовые SEO-запросы, в том числе без согласия Исполнителя осуществлять агентскую деятельность от имени Исполнителя.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Доступ к Сервису предоставляется круглосуточно за исключением случаев проведения профилактических работ.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ПРАВА И ОБЯЗАННОСТИ СТОРОН</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Исполнитель обязан:
                                <ol class="doc-ol">
                                    <li class="doc-li">Предоставить Пользователю доступ к полному функционалу Сервиса после оплаты выбранного Тарифа.</li>
                                    <li class="doc-li">В разумные сроки устранять сбои в работе Сервиса, возникшие по вине Исполнителя.</li>
                                    <li class="doc-li">Осуществлять техническую поддержку Пользователя по вопросам работы с Сервисом в течение 2 (двух) рабочих дней с даты обращения Пользователя в техническую поддержку Исполнителя.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Пользователь обязан:
                                <ol class="doc-ol">
                                    <li class="doc-li">Использовать Сервис с учетом ограничений, предусмотренных настоящим Соглашением.</li>
                                    <li class="doc-li">Выплачивать Исполнителю вознаграждение в соответствии с выбранным Тарифом.</li>
                                    <li class="doc-li">При использовании Сервиса соблюдать Законодательство Российской Федерации.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Исполнитель имеет право:
                                <ol class="doc-ol">
                                    <li class="doc-li">Получать вознаграждение в рамках Соглашения.</li>
                                    <li class="doc-li">В одностороннем порядке расторгнуть Соглашение и (или) блокировать доступ Пользователя к Сервису
                                        в случае нарушения Пользователем настоящего Соглашения и Приложения к нему. Исполнитель не несет ответственности за убытки (ущерб), возникшие у Пользователя в связи с расторжением Соглашения
                                        и (или) блокировкой.</li>
                                    <li class="doc-li">Проводить профилактические работы на сервере, для чего временно приостанавливать доступ к Сервису. О профилактических работах Исполнитель уведомляет Пользователя путем оповещения на Сайте
                                        за 6 часов до начала проведения профилактических работ. Проведение профилактических работ будет осуществляться по возможности в вечернее или ночное время и/или выходные дни.</li>
                                    <li class="doc-li">Модифицировать или выпускать обновления программного обеспечения и Сервиса, добавлять новые свойства или функциональные возможности программного обеспечения, повышающие его работоспособность или иным способом улучшающие его характеристики, в том числе в целях соответствия требованиям Законодательства.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Пользователь имеет право:
                                <ol class="doc-ol">
                                    <li class="doc-li">Получать доступ к Сервису в соответствии с условиями Соглашения.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ПЕРСОНАЛЬНЫЕ ДАННЫЕ. ОБЕСПЕЧЕНИЕ КОНФИДЕНЦИАЛЬНОСТИ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Пользователь дает согласие на обработку Исполнителем персональных данных Пользователя, которые могут быть переданы Исполнителю Пользователем, в том числе: фамилия, имя, отчество, адрес электронной почты, номер контактного телефона, IP-адрес.
                                <ol class="doc-ol">
                                    <li class="doc-li">Настоящее согласие действует бессрочно, срок хранения персональных данных Пользователя
                                        не ограничен.</li>
                                    <li class="doc-li">При обработке персональных данных Пользователя Исполнитель руководствуется Федеральным законом РФ «О персональных данных» № 152-ФЗ от 29.07.2006.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Пользователь имеет право отозвать свое согласие посредством составления соответствующего письменного документа, который может быть направлен Пользователем в адрес Исполнителя  по почте заказным письмом
                                с уведомлением о вручении либо вручен лично под расписку представителю Исполнителя. В случае получения письменного заявления об отзыве настоящего согласия на обработку персональных данных Исполнитель обязан прекратить их обработку.</li>
                            <li class="doc-li">Пользователь осознает и дает согласие, что в результате сбоя, технических неисправностей, действий третьих лиц, в том числе (но не ограничиваясь) вирусной или хакерской атаки, данные Пользователя, размещенные в Сервисе, могут стать доступны третьим лицам. Пользователь обязуется не предъявлять требований к Исполнителю
                                о возмещении убытков (ущерба), возникших в связи с этим.</li>
                            <li class="doc-li">Пользователь дает согласие на:
                                <ol class="doc-ol">
                                    <li class="doc-li">Хранение персональных данных на сервере Исполнителя.</li>
                                    <li class="doc-li">Использование персональных и статистических данных Пользователя для демонстрации рекламных объявлений.</li>
                                    <li class="doc-li">Направление Пользователю информации об услугах, новостях Исполнителя и (или) партнеров Исполнителя.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Исполнитель обрабатывает только данные Пользователя, которые необходимы для исполнения Соглашения.</li>
                            <li class="doc-li">Исполнитель обязуется приложить все усилия для обеспечения конфиденциальности данных, размещенных Пользователем посредством Сервиса, на весь период их нахождения на сервере Исполнителя.</li>
                            <li class="doc-li">Пользователь осведомлен и согласен, что телефонные разговоры с Исполнителем могут быть записаны в целях контроля качества работы Исполнителя.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ВОЗНАГРАЖДЕНИЕ. РАСЧЕТЫ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Размер вознаграждения определяется Тарифом, выбранным Пользователем. Оплата осуществляется путем внесения 100% платежа за календарный период, определяемый для каждого из Тарифов отдельно.</li>
                            <li class="doc-li">В случае неоплаты либо просрочки оплаты Пользователем предусмотренного Соглашением вознаграждения Исполнитель вправе не предоставлять доступ к полному функционалу Сервиса до момента получения соответствующей суммы денежных средств.</li>
                            <li class="doc-li">Вознаграждение выплачивается в российских рублях путем оплаты с использованием безналичных платежных систем либо путем оплаты на расчетный счет Исполнителя на основании счета. Обязанность Пользователя
                                по оплате считается исполненной в момент зачисления денежных средств на счет платежной системы (Исполнителя). Сроки зачисления денежных средств на счет Исполнителя могут составлять до 3 (трех) банковских дней и не зависят от Исполнителя. При зачислении денежных средств Исполнитель направляет на электронную почту письмо об активации Тарифа.</li>
                            <li class="doc-li">В случае если в течение 5 (пяти) рабочих дней с момента окончания оплаченного периода Исполнитель не получил претензий Пользователя, связанных с объемом предоставленных прав, считается, что услуга по предоставлению Сервиса  предоставлена Пользователю в полном объеме и надлежащим образом. Последующие претензии
                                не принимаются.</li>
                            <li class="doc-li">Пользователь имеет право изменить используемый Тариф по окончании Оплаченного периода.</li>
                            <li class="doc-li">В случае расторжения Соглашения и достижения Сторонами письменного соглашения о возврате выплаченных
                                и неиспользованных денежных средств Пользователю денежные средства переводятся по реквизитам, используемым Пользователем при оплате, и только по личному заявлению на возврат денежных средств.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ОТВЕТСТВЕННОСТЬ. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Сервис предоставляется Пользователю «как есть» (as is), в соответствии с общепринятым в международной практике принципом. ИСПОЛНИТЕЛЬ НЕ ДАЕТ НИКАКИХ ГАРАНТИЙ НА ИСПОЛЬЗОВАНИЕ ИЛИ ПРОИЗВОДИТЕЛЬНОСТЬ СЕРВИСА. ЗА ИСКЛЮЧЕНИЕМ ГАРАНТИЙ, УСЛОВИЙ, ПРЕДСТАВЛЕНИЙ ИЛИ ПОЛОЖЕНИЙ, СТЕПЕНЬ КОТОРЫХ НЕ МОЖЕТ БЫТЬ ИСКЛЮЧЕНА ИЛИ ОГРАНИЧЕНА В СООТВЕТСТВИИ С ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, ИСПОЛНИТЕЛЬ НЕ ДАЕТ НИКАКИХ ГАРАНТИЙ, УСЛОВИЙ, ПРЕДСТАВЛЕНИЙ ИЛИ ПОЛОЖЕНИЙ (ВЫРАЖАЕМЫХ В ЯВНОЙ ИЛИ В ПОДРАЗУМЕВАЕМОЙ ФОРМЕ) НА ВСЕ, ВКЛЮЧАЯ БЕЗ ОГРАНИЧЕНИЙ НЕНАРУШЕНИЕ ПРАВ ТРЕТЬИХ ЛИЦ, КОММЕРЧЕСКОЕ КАЧЕСТВО, ИНТЕГРАЦИЮ ИЛИ ПРИГОДНОСТЬ ДЛЯ ОПРЕДЕЛЕННЫХ ЦЕЛЕЙ. ПОЛЬЗОВАТЕЛЬ ПОДВЕРЖДАЕТ, ЧТО ОН НЕСЕТ ОТВЕТСТВЕННОСТЬ ЗА ВЫБОР СЕРВИСА, А ТАКЖЕ ЗА РЕЗУЛЬТАТЫ, ПОЛУЧЕННЫЕ С ЕГО ПОМОЩЬЮ.</li>
                            <li class="doc-li">В МАКСИМАЛЬНОЙ СТЕПЕНИ, ДОПУСКАЕМОЙ ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, ИСПОЛНИТЕЛЬ
                                НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА КАКИЕ-ЛИБО УБЫТКИ И/ИЛИ УЩЕРБ (В ТОМ ЧИСЛЕ УБЫТКИ В СВЯЗИ
                                С НЕДОПОЛУЧЕННОЙ КОММЕРЧЕСКОЙ ПРИБЫЛЬЮ, ПРЕРЫВАНИЕМ ДЕЯТЕЛЬНОСТИ, УТРАТОЙ ИНФОРМАЦИИ ИЛИ ИНОЙ ИМУЩЕСТВЕННЫЙ УЩЕРБ), ВОЗНИКАЮЩИЕ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ ИЛИ НЕВОЗМОЖНОСТЬЮ ИСПОЛЬЗОВАНИЯ СЕРВИСА, ДАЖЕ ЕСЛИ ИСПОЛНИТЕЛЬ БЫЛ УВЕДОМЛЕН О ВОЗМОЖНОМ ВОЗНИКНОВЕНИИ ТАКИХ УБЫТКОВ И/ИЛИ УЩЕРБА. В ЛЮБОМ СЛУЧАЕ ОТВЕТСТВЕННОСТЬ ИСПОЛНИТЕЛЯ ПО ЛЮБОМУ
                                ИЗ ПОЛОЖЕНИЙ НАСТОЯЩЕГО СОГЛАШЕНИЯ ОГРАНИЧИВАЕТСЯ СУММОЙ, ФАКТИЧЕСКИ УПЛАЧЕННОЙ ПОЛЬЗОВАТЕЛЕМ ЗА СЕРВИС. НАСТОЯЩИЕ ОГРАНИЧЕНИЯ НЕ МОГУТ БЫТЬ ИСКЛЮЧЕНЫ ИЛИ ОГРАНИЧЕНЫ
                                В СООТВЕТСТВИИ С ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ.</li>
                            <li class="doc-li">Исполнитель не несет ответственности за негативные последствия, возникающие в процессе получения доступа
                                к Сервису и использовании Сервиса (такие как проблемы совместимости или конфликты с другими программными продуктами (пакетами, драйверами и др.), потеря данных или невозможность дальнейшей работы сайта, в отношении которого предоставляется Сервис,  несоответствие результатов использования Сервиса ожиданиям Пользователя и любые другие негативные последствия).</li>
                            <li class="doc-li">Исполнитель не несет ответственности за невозможность использования Сервиса по причинам, зависящим
                                от действий либо бездействия Пользователя или третьих лиц.</li>
                            <li class="doc-li">Исполнитель не несет ответственности в случае, если доступ к Сервису ограничен в связи с отсутствием доступа Пользователя к сети Интернет.</li>
                            <li class="doc-li">Исполнитель прилагает все возможные усилия для обеспечения нормальной работоспособности Сервиса и Сайта, однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению,
                                а также за прямые и косвенные убытки Пользователя, включая упущенную выгоду и возможный ущерб, возникший, в том числе в результате:
                                <ol class="doc-ol">
                                    <li class="doc-li">неправомерных действий пользователей сети Интернет, направленных на нарушение информационной безопасности или нормального функционирования Сервиса;</li>
                                    <li class="doc-li">отсутствия (невозможности установления, прекращения и пр.) интернет-соединения между Пользователем и сервером Исполнителя;</li>
                                    <li class="doc-li">вмешательства государственных и муниципальных органов, а также проведения оперативно-розыскных мероприятий;</li>
                                    <li class="doc-li">установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения;</li>
                                    <li class="doc-li">других случаев, связанных с действиями (бездействием) пользователей сети Интернет и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования, существовавшей на момент заключения Соглашения.</li>
                                </ol>
                            </li>
                            <li class="doc-li">Пользователь обязуется при использовании Сервиса соблюдать Законодательство, и не нарушать интересы третьих лиц. </li>
                            <li class="doc-li">Пользователь понимает, принимает и соглашается, что он должен самостоятельно оценивать все риски, связанные с использованием Сервиса, включая оценку его надежности, полноты или полезности.
                                Пользователь самостоятельно несет все риски любого использования и/или невозможности использования Сервиса.
                            </li>
                            <li class="doc-li">При любых обстоятельствах ответственность Исполнителя ограничивается суммой вознаграждения.</li>
                            <li class="doc-li">Исполнитель не несет ответственности за последствия использования Сервиса Пользователем (в том числе
                                в случае причинения Пользователем вреда третьим лицам с использованием Сервиса)</li>
                            <li class="doc-li">В случае возникновения между Пользователем и Исполнителем споров или разногласий, вытекающих
                                из Соглашения или связанных с ним отношений, стороны примут все меры к их разрешению путем совместных переговоров. Стороны признают обязательный досудебный порядок разрешения споров или разногласий путем направления одной стороной письменной претензии. Срок рассмотрения претензии составляет 5 (пять) рабочих дней.</li>
                            <li class="doc-li">В случае если не удается разрешить возникшие между сторонами споры и/или разногласия путем переговоров
                                или направления претензии, такие споры рассматриваются в Арбитражном суде г. Москвы либо в суде общей юрисдикции по месту нахождения Исполнителя.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ, ПОРЯДОК РАСТОРЖЕНИЯ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Соглашение вступает в силу с даты акцепта и действует до расторжения Сторонами.</li>
                            <li class="doc-li">Настоящее Соглашение является публичной офертой. Исполнитель имеет право на отзыв данной публичной оферты путем отзыва настоящего Соглашения Исполнителем в течение срока его действия. Отзыв осуществляется путем размещения соответствующей заявки в форме технической поддержки. В случае отзыва настоящее Соглашение считается прекращенным с момента размещения на Сайте информации об отзыве настоящего Соглашения.</li>
                            <li class="doc-li">Соглашение может быть расторгнуто досрочно:
                                <ol class="doc-ol">
                                    <li class="doc-li">по взаимному соглашению Исполнителя и Пользователя;</li>
                                    <li class="doc-li">по инициативе Исполнителя в одностороннем внесудебном порядке в случае нарушения Пользователем условий Соглашения.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>ПРОЧИЕ УСЛОВИЯ</b>
                        <ol class="doc-ol">
                            <li class="doc-li">Предоставление доступа к Сервису на основании настоящего Соглашения распространяется на все последующие обновления и новые версии Сервиса.</li>
                            <li class="doc-li">Все вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с Законодательством.</li>
                            <li class="doc-li">В рамках Соглашения Исполнитель не предоставляет Пользователю услуги связи, не организует для него возможность доступа к информационным системам информационно-телекоммуникационных сетей,
                                не осуществляет деятельность по приему, обработке, хранению, передаче и доставке сообщений электросвязи.</li>
                        </ol>
                    </li>
                    <li class="doc-li"><b>РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</b>
                        <p>АО «Инфорус»<br />
                            ОГРН – 1127746519988<br />
                            ИНН – 7705990670<br />
                            КПП – 773401001<br />
                            Адрес местонахождения: 123060, Россия, г. Москва, ул. Маршала Соколовского., д.9, этаж 2, пом. I, комн. 1.</p>
                    </li>
                </ol>
                <div class="important-text">
                    <hr>
                    <p>Внимательно прочитайте текст перед принятием условий Соглашения.</p>
                    <p>Акцептуя настоящее Соглашение, Вы подтверждаете, что полностью ознакомлены с его текстом и согласны.</p>
                    <p>Безусловным акцептом Пользователем Соглашения является нажатие кнопки «Далее» после фразы «С условиями Соглашения об оказании услуги ознакомился и согласен».</p>
                    <hr>
                    <p>С условиями Соглашения об оказании услуги ознакомился и согласен</p>
                </div>
                <div class="d-md-flex justify-content-md-end">
                    <button type="button" class="btn btn-secondary btn-lg me-md-2 dalee">Далее</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PubOffer',
  components: {},
}
</script>

<style>
</style>